<template>
<!--    <v-app>-->
<!--        <v-row>-->
            <b-form class="kt-form" @submit.stop.prevent="submit" style="width: 100%">
                <v-row v-if="infoError">
                    <v-col cols="1"></v-col>
                    <v-col cols="10">
                        <div
                                role="alert"
                                v-bind:class="{ show: infoError }"
                                class="alert fade alert-danger"
                        >
                            <div class="alert-text" v-for="(error, i) in errors" :key="i">
                                {{ error[0] }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="5">
                        <b-form-group
                                id="profile-email"
                                label="Ingrese su correo electronico"
                                label-for="profile-email">
                            <b-form-input
                                    :disabled="!isNew"
                                    id="profile-email"
                                    name="profile-email"
                                    v-model="$v.form.email.$model"
                                    :state="validateState('email')"
                                    aria-describedby="profile-email-live-feedback"
                                    placeholder="Correo electrónico"
                            ></b-form-input>

                            <b-form-invalid-feedback id="profile-email-live-feedback">
                                Verifique el correo electrónico ingresado
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <v-row>
                            <v-col cols="6">
                                <b-form-group
                                        id="profile-name"
                                        label="Ingrese el nombre"
                                        label-for="profile-name"
                                >
                                    <b-form-input
                                            id="profile-name"
                                            name="profile-name"
                                            v-model="$v.form.firstname.$model"
                                            :state="validateState('firstname')"
                                            aria-describedby="profile-name-live-feedback"
                                            placeholder="Nombre"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="profile-name-live-feedback">
                                        El nombre es requerido.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </v-col>
                            <v-col cols="6">
                                <b-form-group
                                        id="example-input-group-00"
                                        label="Ingrese el apellido"
                                        label-for="example-input-00"
                                >
                                    <b-form-input
                                            id="example-input-00"
                                            name="example-input-00"
                                            v-model="$v.form.lastname.$model"
                                            :state="validateState('lastname')"
                                            aria-describedby="input-00-live-feedback"
                                            placeholder="Apellido"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="input-00-live-feedback">
                                        El apellido es requerido.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </v-col>
                        </v-row>
                        <b-form-group
                                id="profile-document-type"
                                label="Seleccione un tipo de documento"
                                label-for="profile-document-type"
                        >
                            <b-form-select id="profile-document-type"
                                           name="profile-document-type"
                                           v-model="$v.form.documentType.$model"
                                           :options="documents"
                                           text-field="name"
                                           value-field="id"
                                           :state="validateState('documentType')"
                                           aria-describedby="profile-document-type-live-feedback">

                            </b-form-select>
                            <b-form-invalid-feedback id="profile-document-type-live-feedback">
                                El tipo de documento es requerido
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                                id="profile-document"
                                label="Ingrese el número de documento"
                                label-for="profile-document"
                        >
                            <b-form-input
                                    id="profile-document"
                                    name="profile-document"
                                    v-model="$v.form.document.$model"
                                    :state="validateState('document')"
                                    aria-describedby="profile-document-live-feedback"
                                    placeholder="Documento"
                                    maxlength="13"
                            ></b-form-input>

                            <b-form-invalid-feedback id="profile-document-live-feedback">
                                El documento es requerido.
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <v-row v-show="admin">
                            <v-col>
                                <b-form-group
                                        id="profile-password"
                                        label="Ingrese la nueva contraseña"
                                        label-for="example-input-2">
                                    <b-form-input
                                            type="password"
                                            id="profile-password"
                                            name="profile-password"
                                            v-model="$v.form.password.$model"
                                            :state="validateState('password')"
                                            aria-describedby="profile-password-live-feedback"
                                            placeholder="Password"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="profile-password-live-feedback">
                                        Password no válido.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </v-col>
                            <v-col>
                                <b-form-group
                                        id="profile-cpassword"
                                        label="Confirmación de password"
                                        label-for="profile-cpassword">
                                    <b-form-input
                                            type="password"
                                            id="profile-cpassword"
                                            name="profile-cpassword"
                                            v-model="$v.form.c_password.$model"
                                            :state="validateState('c_password')"
                                            aria-describedby="profile-cpassword-live-feedback"
                                            placeholder="Confirmación de password"
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="profile-cpassword-live-feedback">
                                        Confirmación de password debe conincidir.
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </v-col>
                        </v-row>
                        <!--end::Action-->
                    </v-col>
                    <v-col cols="5">
                        <b-form-group
                                id="profile-phone"
                                label="Ingrese el numero de celular"
                                label-for="profile-phone"
                        >
                            <b-form-input
                                    id="profile-phone"
                                    name="profile-phone"
                                    v-model="$v.form.phone.$model"
                                    :state="validateState('phone')"
                                    aria-describedby="profile-phone-live-feedback"
                                    placeholder="Número de teléfono"
                                    maxlength="10"
                            ></b-form-input>
                            <b-form-invalid-feedback id="profile-phone-live-feedback">
                                El número de teléfono es requerido.
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <v-row>
                            <v-col cols="12">
                                <b-form-group
                                        id="profile-province"
                                        label="Seleccione una provincia"
                                        label-for="profile-province"
                                >
                                    <b-form-select id="profile-province"
                                                   name="profile-province"
                                                   v-model="$v.form.province.$model"
                                                   :options="provinces"
                                                   text-field="nombre"
                                                   value-field="id"
                                                   :state="validateState('province')"
                                                   aria-describedby="profile-province-live-feedback">

                                    </b-form-select>

                                    <!--                                                <b-form-invalid-feedback id="input-0-live-feedback">-->
                                    <!--                                                    El nombre es requerido.-->
                                    <!--                                                </b-form-invalid-feedback>-->
                                </b-form-group>
                            </v-col>
                        </v-row>
                        <b-form-group
                                id="profile-city"
                                label="Ingrese la ciudad"
                                label-for="profile-city"
                        >
                            <b-form-input
                                    id="profile-city"
                                    name="profile-city"
                                    v-model="$v.form.city.$model"
                                    :state="validateState('city')"
                                    aria-describedby="profile-city-live-feedback"
                                    placeholder="Ciudad de domicilio"
                            ></b-form-input>
                            <b-form-invalid-feedback id="profile-city-live-feedback">
                                La ciudad de domicilio es requerido.
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group
                                id="profile-address"
                                label="Ingrese la dirección"
                                label-for="profile-address"
                        >
                            <b-form-input
                                    id="profile-address"
                                    name="profile-address"
                                    v-model="$v.form.address.$model"
                                    :state="validateState('address')"
                                    aria-describedby="profile-address-live-feedback"
                                    placeholder="Dirección"
                            ></b-form-input>

                            <b-form-invalid-feedback id="profile-address-live-feedback">
                                El dirección es requerida
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <v-row>
                            <v-col cols="12">
                                <div class="kt-form__actions" v-show="!admin">
                                    <button id="kt_submit" type="submit"
                                            class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right pull-right">
                                        Guardar
                                    </button>
                                </div>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
            </b-form>
<!--        </v-row>-->
<!--    </v-app>-->
</template>

<script>
    import storeService from "@/common/jwt.service";
    import {email, minLength, numeric, required, sameAs} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";
    import Swal from "sweetalert2";
    import {UPDATE_USER} from "@/store/auth.module";
    import catalogueService from "@/service/catalogue.service"
    import service from "@/service/users.service"

    export default {
        name: "User",
        mixins: [validationMixin],
        props: ['admin', 'isNew', 'currentItem', 'postPayment'],
        data() {
            return {
                dialog: false,
                infoError: null,
                errors: [],
                documents: [
                    {
                        name: "Cédula",
                        id: "CI"
                    },
                    {
                        name: "RUC",
                        id: "RUC"
                    }
                ],
                form: {
                    email: "",
                    firstname: "",
                    lastname: "",
                    phone: "",
                    document: "",
                    documentType: "",
                    address: "",
                    province: "",
                    city: "",
                    c_password: "",
                    password: ""
                },
                provinces: [],

            }
        },
        validations() {
            return {
                form: {
                    email:  this.respNew ? {
                            required,
                            email
                        } : {
                            minLength: minLength(6),
                            email
                    },
                    firstname: {
                        required,
                        minLength: minLength(5)
                    }, lastname: {
                        required,
                        minLength: minLength(3)
                    }, phone: {
                        required,
                        minLength: minLength(6)
                    }, documentType: {
                        required,
                        minLength: minLength(1)
                    }, document: {
                        required,
                        numeric,
                        minLength: minLength(10)
                    }, city: {
                        required,
                        minLength: minLength(5)
                    }, address: {
                        required,
                        minLength: minLength(10)
                    }, province: {},
                    password: this.respNew ? {
                        required,
                        minLength: minLength(6),
                    }: {},
                    c_password: {
                        sameAsPassword: sameAs('password')
                    }
                }
            }
        },
        mounted() {
            this.loading = true;
            catalogueService.getCatalogue('provincias').then(x => {
                this.provinces = x.data.data;
                this.loading = false;
            });

            this.$set(this, 'form', this.formatUser());

        },
        computed: {
            user() {
                return this.admin ? this.currentItem :storeService.getUser();
            },
            respNew() {
                return this.isNew;
            }
        },
        methods: {
            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            submit: function (e) {
                return new Promise((resolve, reject) => {
                    this.infoError = null;
                    e.preventDefault();
                    this.$v.form.$touch();
                    if (this.$v.form.$anyError) {
                        return;
                    }
                    const firstname = this.$v.form.firstname.$model;
                    const lastname = this.$v.form.lastname.$model;
                    const provincia_id = this.$v.form.province.$model;
                    const ciudad = this.$v.form.city.$model;
                    const direccion = this.$v.form.address.$model;
                    const celular = this.$v.form.phone.$model;
                    const documento = this.$v.form.document.$model;
                    const tipodocumento = this.$v.form.documentType.$model;
                    const password = this.respNew ? this.$v.form.password.$model :undefined;
                    const c_password = this.respNew ? this.$v.form.c_password.$model : undefined;
                    const email = this.$v.form.email.$model;
                    let promise = null;
                    if(!this.admin) {
                        promise = this.update({
                            firstname,
                            lastname,
                            provincia_id,
                            ciudad,
                            direccion,
                            tipodocumento,
                            documento,
                            celular
                        })
                    }
                    else {
                        promise = this.manage( {
                            firstname,
                            lastname,
                            provincia_id,
                            ciudad,
                            direccion,
                            tipodocumento,
                            documento,
                            celular,
                            email,
                            password,
                            c_password
                        })
                    }
                    const submitButton = document.getElementById("kt_submit");
                    submitButton.classList.add("kt-spinner");
                    promise.then((r) => {
                        Swal.fire({
                            title: "",
                            text: "Registrado correctamente!",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            preConfirm: () => {
                                if(this.postPayment) {
                                    this.$router.push({
                                        name: 'preview', params: this.postPayment
                                    })
                                }
                                else if( !this.admin) {
                                        this.$router.push('/home');
                                }
                            },
                        });

                        resolve(r)
                    }).catch(err => {
                        this.infoError = err.data ? err.data.message : "Un error ha ocurrido";
                        this.errors = err.data ? err.data.errors : [['Un error ah courrido']];
                        reject(err);
                    }).finally(() => {
                        submitButton.classList.remove("kt-spinner");
                    })
                });


            },
            update(payload) {
                return this.$store.dispatch(UPDATE_USER, payload )
            },
            manage(payload) {
                return service.register( payload, this.currentItem ? this.currentItem.id : undefined)
            },
            formatUser() {
                const user = this.user;
                const result = {};
                result.email = user ? user.email : "";
                result.firstname = user ? user.firstname : "";
                result.lastname = user ? user.lastname : "";
                result.province = user ? user.provincia_id : "";
                result.city = user ? user.ciudad : "";
                result.address = user ? user.direccion : "";
                result.phone = user ? user.celular : "";
                result.document = user ? user.documento : "";
                result.documentType = user ? user.tipodocumento : "";
                result.password =  "";
                result.c_password = "";
                return result;
            }
        }
    }
</script>

<style scoped>

</style>
